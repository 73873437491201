<template>
    <v-row no-gutters class="ma-0 pa-0">
        <v-col cols="12">
            <v-card outlined class="mx-1 mb-3">
                <div class="d-block text-right pa-2">
                <v-radio-group hide-details class="d-inline-block pa-0 ma-0" row v-model="grafico_type" @change="$forceUpdate()">
                    <v-radio :ripple="false" value="bar" label="Barras" />
                    <v-radio :ripple="false" value="area" label="Áreas" />
                    <v-radio :ripple="false" value="line" label="Lineas" />
                </v-radio-group>
                </div>
                <SimpleXYChart :series="value.grafico" :type="grafico_type" ytype="numeric" :extra="grafico_config" />
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
            <v-data-table dense :headers="headers" :items="value.tabla" :items-per-page="10">
                <template v-slot:item.nombre="{ item }">
                    <span :title="item.nombre">
                        {{ item.nombre | see_more(50) }}
                    </span>
                </template>
                <template v-slot:item.curso="{ item }">
                    <span :title="item.curso">
                    <v-icon left>folder</v-icon>{{ item.curso | see_more(50) }}
                    </span>
                </template>
                <template v-slot:item.rank_posicion="{ item }">
                    {{ item.rank_posicion }}º
                </template>
                <template v-slot:item.rank_incorrectas="{ item }">
                    <v-row align="center" justify="center">
                        <div>{{ item.rank_incorrectas }}</div>
                    </v-row>
                </template>
            </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import SimpleXYChart from '@/dash/components/SimpleXYChart'

export default {
    props: ['value'],
    components: {
        SimpleXYChart
    },
    data: () => ({
        headers: [
            { value: "rank_posicion", text: "" },
            { value: "curso", text: "Curso" },
            { value: "nombre", text: "Evaluación" },
            { value: "rank_incorrectas", text: "Incorrectas" , align: "end" },
        ],
        grafico_type: 'bar',
        grafico_config: {
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val)
                }
            },
            colors: ['#F44336', '#4CAF50'],
            markers: {
                size: 5, 
                colors: ['#F44336', '#4CAF50']
            }
        }
    })
}
</script>