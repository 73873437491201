<template>
    <v-row no-gutters class="ma-0">
        <v-col cols="12" sm="3">
            <ProgressCircle v-model="value.rel_correctas" color="green" text-color="gray" title="Correctas" />
        </v-col>
        <v-col cols="12" sm="3">
            <ProgressCircle v-model="value.rel_incorrectas" color="red" text-color="gray" title="Incorrectas" />
        </v-col>
        <v-col cols="12" sm="6" class="pa-2">
            <!-- <v-alert type="info" color="#555">Seleccione un grupo y evaluación para ver un gráfico y tabla de rendimiento por evaluación</v-alert>
            <v-divider class="my-4" /> -->
            <v-label>Seleccione grupo de acceso</v-label>
            <v-select 
                clearable dense flat solo outlined hide-details 
                prepend-inner-icon="group" label="Grupo de acceso"
                v-model="grupo" :items="grupos" @change="evaluacion = null" />
            <v-divider class="my-4" />
            <v-label>Seleccione una evaluación</v-label>
            <v-select
                clearable dense flat solo outlined hide-details 
                prepend-inner-icon="description" label="Evaluacion" 
                :item-text="(i) => `${i.evaluacion.nombre} (${i.evaluacion.curso})`"
                return-object
                v-model="evaluacion" :items="evaluaciones"/>
        </v-col>
        <v-col cols="12" v-if="!!evaluacion" class="pb-2">
            <v-card outlined class="py-2 mb-3">
                <span class="d-block text-center subtitle-2">Gráfico de respuestas de evaluación</span>
                <span class="d-block text-center title">{{ evaluacion.evaluacion.nombre }}</span>
                <span class="d-block text-center subtitle-1"><v-icon left>folder</v-icon>Del curso {{ evaluacion.evaluacion.curso }}</span>
                <v-divider class="my-2" />
                <span class="d-block text-center subtitle-1"><v-icon left>timer</v-icon>Duración de realización: {{ evaluacion.duracion }}</span>
            </v-card>
            <v-card outlined>
                <SimpleXYChart type="area" :series="evaluacion.serie" xtype="category" ytype="integer" :extra="graph_config" />
            </v-card>
        </v-col>
        <v-col cols="12" class="pa-0" v-if="!!evaluacion">
            <v-card outlined>
                <v-data-table dense :headers="headers" :items="evaluacion.respuestas">
                    <template v-slot:item.pregunta__texto="{ item }">
                        <span :title="item.pregunta__texto">
                            {{ item.pregunta__texto | see_more(80) }}
                        </span>
                    </template>
                    <template v-slot:item.incorrectas="{ item }">
                        {{ item.incorrectas }} {{ item.incorrectas == 1 ? "vez." : "veces." }}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <v-col cols="12" v-else>
            <v-card outlined>
                <span class="d-block pa-2 font-weight-regular title text-center">Resumen de realización de cada prueba</span>
                <v-divider />
                <v-data-table dense :headers="resumen_headers" :items="logs_resumen" group-by="grupo">
                    <template v-slot:group.header="{ group, toggle, isOpen, headers }">
                        <td :colspan="headers.length" class="pa-0" @click="toggle">
                            <v-row no-gutters justify="center">
                                <v-col class="flex-grow-1 flex-shrink-0 fill-height pa-2">
                                    <span :title="group">
                                        <v-icon left>group</v-icon>{{ group | see_more(140) }}
                                    </span>
                                </v-col>
                                <v-col class="flex-grow-0 flex-shrink-1 pa-2">
                                    <v-icon v-if="!isOpen">expand_more</v-icon>
                                    <v-icon v-else>expand_less</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-slot:item.total_correctas="{ item }">
                        <v-icon left color="green">check</v-icon>{{ item.total_correctas }}
                    </template>
                    <template v-slot:item.total_incorrectas="{ item }">
                        <v-icon left color="red">close</v-icon>{{ item.total_incorrectas }}
                    </template>
                    <template v-slot:item.evaluacion.curso="{ item }">
                        <v-icon left>folder</v-icon>{{ item.evaluacion.curso }}
                    </template>
                    <template v-slot:item.duracion="{ item }">
                        <v-icon left>timer</v-icon>{{ item.duracion }}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import EmptyCard from '@/shared/components/EmptyCard'
import moment from 'moment'

export default {
    props: ['value'],
    components: {
        ProgressCircle,
        ProgressCounter,
        SimpleXYChart,
        EmptyCard
    },
    data: () => ({
        grupo: null,
        evaluacion: null,
        resumen_headers: [
            { value: "grupo", text: "Grupo ", sortable: false },
            { value: "evaluacion.curso", text: "Curso ", width: "30%", sortable: false },
            { value: "evaluacion.nombre", text: "Evaluacion ", width: "30%", sortable: false },
            { value: "total_correctas", text: "Correctas ", width: "10%", align: "center", sortable: false },
            { value: "total_incorrectas", text: "Incorrectas ", width: "10%", align: "center", sortable: false },
            { value: "duracion", text: "Duración ", width: "20%" }
        ],
        headers: [
            { value: "pregunta__qid", text: "Nº", width: "5%", sortable: false },
            { value: "pregunta__texto", text: "Pregunta", width: "75%" },
            { value: "incorrectas", text: "Veces fallidas", width: "25%" }
        ],
        graph_config: {
            yaxis: {
                type: 'numeric',
                labels: {
                    formatter: (val) => Math.round(val)
                }
            },
            colors: ['#F44336', '#4CAF50'],
            markers: {
                size: 5, 
                colors: ['#F44336', '#4CAF50']
            }
        }
    }),
    computed: {
        logs_resumen() {
            var registros = this.value.logs.map((x, i) => x.registros);
            return this.value.logs.map((x, i) => registros[i].map(r => ({...r, grupo: x.grupo }))).flat();
        },
        logs() {
            return this.value.logs;
        },
        evaluaciones() {
            var grupo = this.logs.filter(x => x.grupo == this.grupo)[0];

            if(!grupo) {
                return [];
            }
            return grupo.registros;
        },
        grupos() {
            return this.logs.map(x => x.grupo);
        }
    },
    methods: {
        reset() {
            this.grupo = null;
            this.evaluacion = null;
        }
    }
}
</script>

<style scoped>
/* Estilo solo para ${i.evaluacion.curso} */
.curso-text {
  color: rgb(27, 27, 49); /* Cambia este color al que desees */
  font-weight: bold; /* También puedes aplicar otros estilos */
}
</style>